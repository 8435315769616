(function ($, Drupal) {
  var footerLanguageMenu = {
    setup: function (context) {
      var self = this;

      if (self.attached) {
        return;
      }
      self.attached = true;
      self.init(context);
    },
    init: function (context) {
      var self = this;
      var $languageMenu = $('.js-footer-language-menu-v1', context);
      var $menu = $languageMenu.find('.js-footer-language-menu__select');
      var $menuItem = $menu.find('option');
      var forceLanguageSwitch = $languageMenu.data().forceLanguageSwitch;
      var $option = $();
      var $activeSelectboxItem = $();
      var $localeCookie = $.cookie('LOCALE');
      var localeCookieLanguage = $localeCookie?.split('_')[0];
      var urlParam = window.location.search;
      var urlLanguage;
      var optionValue;
      var language;

      self.languageDefault = $languageMenu.data().defaultLanguage;
      self.path = window.location.pathname;
      self.pathArray = self.path.split('/');
      self.languageArray = [];
      self.localeCode = $languageMenu.data().localeCode;
      self.activeLanguage = self.languageDefault;

      self.isAccountUrl = self.path.includes('/account') === true;
      self.isCheckoutUrl = self.path.includes('/checkout') === true;
      self.isSearchUrl = self.path.includes('/esearch') === true;
      self.isUrlLanguage = urlParam.includes('?LOCALE=') === true;

      $menuItem.each(function () {
        $option = $(this);
        optionValue = $option.val();

        self.languageArray.push(optionValue);

        if (optionValue === self.pathArray[1]) {
          $option.prop('selected', true);
          self.activeLanguage = optionValue;
        }
      });

      $menu.selectBox({
        menuTransition: 'fade',
        menuSpeed: 'fast',
        mobile: false,
        hideOnWindowScroll: true
      });
      $menu.selectBox().change(function () {
        language = $(this).val();
        self.setCookie(language);
        self.switchLanguage(language);
      });

      $activeSelectboxItem = $('.js-footer-language-menu-item a[rel="' + self.activeLanguage + '"]', context);
      $activeSelectboxItem.hide();

      if (
        $localeCookie !== null &&
        forceLanguageSwitch === true &&
        localeCookieLanguage !== self.activeLanguage &&
        self.isAccountUrl !== true &&
        self.isCheckoutUrl !== true &&
        self.isSearchUrl !== true &&
        self.isUrlLanguage !== true
      ) {
        $(self.languageArray).each(function (i, lang) {
          if (lang === localeCookieLanguage) {
            self.setCookie(localeCookieLanguage);
            self.switchLanguage(localeCookieLanguage);
          }
        });
      }

      if (self.isUrlLanguage === true) {
        urlLanguage = urlParam.split('=')[1];
        language = urlLanguage.split('_')[0];
        self.setCookie(language);
        self.switchLanguage(language);
      }
    },
    switchLanguage: function (language) {
      var self = this;
      var newPath;
      var languagePath;
      var path = self.path.substring(1);

      if (!language) {
        return;
      }

      $(self.languageArray).each(function (i, lang) {
        newPath = path.replace(lang + '/', '');
      });

      languagePath = language === self.languageDefault ? (newPath === path ? '' : newPath) : language + '/' + newPath;

      window.location.href = '/' + languagePath;
    },
    setCookie: function (language) {
      var self = this;
      var cookieValue = language + '_' + self.localeCode;

      if (!self.localeCode) {
        return;
      }

      $.cookie('LOCALE', cookieValue, {
        path: '/'
      });
    }
  };

  Drupal.behaviors.footerLanguageMenuV1 = {
    attached: false,
    path: '',
    pathArray: [],
    language: '',
    languageDefault: '',
    languageArray: [],
    localeCode: '',
    activeLanguage: '',
    isAccountUrl: false,
    isCheckoutUrl: false,
    isSearchUrl: false,
    isUrlLanguage: false,
    attach: function (context) {
      footerLanguageMenu.setup(context);
    }
  };
}(jQuery, Drupal));
